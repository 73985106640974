<div class="hub-layout-container" [ngClass]="{'show-banner': showBanner}">

    <div class="hub-header-container">
        <app-header #header [showBanner]="showBanner" [isSidebarCollapsed]="!header.isSidebarExpanded"></app-header>
    </div>

    <div class="hub-sidebar-navs-container" [ngClass]="{'is-sidebar-collapsed': !header.isSidebarExpanded }">
        <app-sidebar-nav #sidebarNav *ngIf="isLoggedIn()" [isSidebarExpanded]="header.isSidebarExpanded">
        </app-sidebar-nav>
    </div>

    <div class="hub-body-container">
        <router-outlet></router-outlet>
    </div>

    <div class="hub-footer-container">
        <app-footer></app-footer>
    </div>
</div>

<app-hub-toast></app-hub-toast>

<app-error-container></app-error-container>
