<div class="legal-disclaimer-container">
    <h2 class="mat-dialog-title">
        Legal Disclaimer
    </h2>
    <div class="mat-dialog-content">
        Because this information is accessible by all TNC staff, and in order to preserve confidential information,
        please ensure that information provided/added is neither "Restricted" nor "Highly Sensitive" per the
        Information in the
        <a href="https://thenatureconservancy462.sharepoint.com/sites/Department/EthicsCompliance/PoliciesProcedures/Manual/Legal_Information-Data%20Classification%20and%20Security_SOP.pdf"
            target="_new">Data Classification SOP</a>
        and that the personal data of our members, donors, customers,
        employees, partners, vendors and service providers is protected from unauthorized access, use,
        disclosure, modification or loss per the
        <a href="https://thenatureconservancy462.sharepoint.com/sites/Department/EthicsCompliance/PoliciesProcedures/Manual/Legal_Privacy%20and%20Personal%20Data%20Protection_SOP.pdf"
            target="_new">Privacy and Personal Data Protection SOP</a>.
        You may direct any questions or concerns regarding data classification to your TNC attorney. You may direct
        any questions or concerns regarding privacy and personal data protection to the TNC Privacy Officer at
        <a href="mailto:compliance@tnc.org">compliance@tnc.org</a>.
    </div>
    <div class="mat-dialog-actions">
        <button mat-dialog-close>CLOSE</button>
    </div>
</div>