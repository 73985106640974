<div [formGroup]="intermediateResultForm" *ngIf="intermediateResultForm" class="intermediate-result-dialog-container">

    <ng-template #metricNameTemplate>

        <div class="hub-select-container" [formGroup]="getFormGroupByTabIndex(0)">

            <mat-form-field>
                <input type="text" formControlName="metricName" matInput [matAutocomplete]="auto2"
                    placeholder="Enter a name for your metric here..." />
                
            </mat-form-field>

            <mat-autocomplete #auto2="matAutocomplete">
                <mat-option *ngFor="let metricName of metricSuggestions | async" [value]="metricName">
                    {{metricName}}
                </mat-option>
            </mat-autocomplete>
        </div>

        <small class="hub-validation-error-message">
            {{this.getValidationErrorMessages(0, 'metricName')}}
        </small>
    </ng-template>

    <ng-template #unitTemplate>

        <div class="hub-select-container" [formGroup]="getFormGroupByTabIndex(0)">
            <mat-form-field>
                <input type="text" matInput formControlName="unit" [matAutocomplete]="auto1"
                    placeholder="Enter a name for your unit here..." />
            </mat-form-field>

            <mat-autocomplete #auto1="matAutocomplete">
                <mat-option *ngFor="let unit of unitSuggestions | async" [value]="unit">
                    {{unit}}
                </mat-option>
            </mat-autocomplete>
        </div>


        <small class="hub-validation-error-message">
            {{this.getValidationErrorMessages(0, "unit")}}
        </small>
    </ng-template>

    <ng-template #scaleMeasureTemplate [formGroup]="getFormGroupByTabIndex(1)">
        <div class="scale-measure-toggle" [ngClass]="{'has-parent-ir': hasParentIr }">

            <span class="select-label text-info-800 d-block">
                Scale Measure?
                <app-help-selector [selector]="1195"></app-help-selector>
            </span>

            <div class="toggle-panel" *ngIf="!hasParentIr; else strategyScaleMeasure">
                <span>NO</span>
                <mat-slide-toggle color="primary" formControlName="isScaleMeasure"></mat-slide-toggle>
                <span>YES</span>
            </div>

            <ng-template #strategyScaleMeasure>
                <div *ngIf="!parentIr.scaleMeasureId">NO</div>
                <div *ngIf="parentIr.scaleMeasureId">YES</div>
            </ng-template>

        </div>

        <div class="scale-measure-container" [hidden]="!isScaleMeasure && !parentIr?.scaleMeasureId"
            formArrayName="scaleMeasureItems">

            <div class="heading">
                Scale Measure Description
                <app-help-selector [selector]="1196"></app-help-selector>
            </div>

            <div *ngIf="!parentIr" class="instruction-text">Define each degree of your scale here</div>

            <table class="scale-measure-descriptions">

                <tr class="scale-measure-description-row"
                    *ngFor="let scaleItemGroup of scaleMeasureItemsFormArray.controls; let i = index">

                    <td class="label-column">
                        <span>{{getScaleMeasureItem(i).value}}</span> -
                        <span>{{getScaleMeasureItem(i).label}}</span> <span class="scale-measure-required">*</span>
                    </td>
                    <td class="input-column" [formGroup]="scaleMeasureItemsFormArray.controls[i]">
                        <input *ngIf="!parentIr" type="text" class="form-control" formControlName="definition" />
                        <span *ngIf="parentIr">{{parentIr.scaleMeasureDefinitions[i]?.description}}</span>
                    </td>
                    <td class="validation-error-column">
                        <small class="hub-validation-error-message">
                            {{this.getScaleMeasureDescriptionErrorMessaged(i)}}
                        </small>
                    </td>

                </tr>
            </table>

        </div>

    </ng-template>

    <div mat-dialog-title>
        <h5 class="dialog-header" *ngIf="!existingIr">Create an Intermediate Result for your
            {{project?.projectType?.name}}</h5>
        <h5 class="dialog-header" *ngIf="existingIr">Edit Intermediate Result</h5>
    </div>

    <mat-dialog-content mat-dialog-content class="hub-custom-scrollbar">

        <ng-container *ngIf="!isStrategy">

            <app-hub-stepper #stepper linear>

                <cdk-step label="Intermediate Result Type" [stepControl]="getFormGroupByTabIndex(0)">
                    <div class="step-content-container" [formGroup]="getFormGroupByTabIndex(0)">
                        <div class="step-content-header">

                            <p class="text-center font-weight-bold text-info-800 p-1">
                                Select the type of Intermediate Result
                            </p>

                            <div class="select-label text-info-800">Intermediate Result Type</div>

                            <div class="rollup-panel sb-b">

                                <span>
                                    Will progress roll up to an IR of a related strategy?
                                </span>

                                <div class="toggle-panel">
                                    <span>NO</span>
                                    <mat-slide-toggle color="primary" formControlName="isChildIr"></mat-slide-toggle>
                                    <span>YES</span>
                                </div>

                            </div>

                        </div>
                        <div class="step-content-body hub-custom-scrollbar">
                            <ng-container *ngIf="isChildIr">

                                <mat-radio-group formControlName="parentIr" class="mt-2">
                                    <div *ngIf="availableParents.length; else noRelations">
                                        <div class="select-label pt-2">Select a Strategy and Intermediate Result</div>

                                        <div class="ir-list">

                                            <div *ngFor="let availableParent of availableParents">
                                                <h4>{{availableParent.strategyName}}</h4>

                                                <div class="selectable-ir-display"
                                                    *ngFor="let ir of availableParent.intermediateResults">
                                                    <ng-container>
                                                        <mat-radio-button color="primary" [value]="ir"
                                                            [id]="'parentIr_' + ir.projectIntermediateResultId">
                                                        </mat-radio-button>

                                                        <div class="ir-display">
                                                            <div style="font-weight: bold;">{{ir.indicatorName}}
                                                                ({{ir.unit}})</div>
                                                            <div class="text-muted record-description">
                                                                {{ir.statement}}</div>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <ng-template #noRelations>
                                        <div class="no-relations">
                                            <div class="alert alert-info alert-styled-left shadow-0 mr-3 ng-star-inserted"
                                                style="margin-top: 10px;">
                                                You currently do not have any Related Strategies and/or your Related
                                                Strategies have no Intermediate Results of the same type as yours.
                                            </div>
                                        </div>
                                    </ng-template>

                                </mat-radio-group>
                            </ng-container>

                            <ng-container *ngIf="!isChildIr">
                                <div style="margin-top: 20px;">
                                    <span class="select-label text-info-800">
                                        Intermediate Result Metric<app-help-selector [selector]="1107">
                                        </app-help-selector>
                                    </span>
                                    <span class="alpaca-icon-required text-danger"></span>
                                </div>

                                <ng-container *ngTemplateOutlet="metricNameTemplate"></ng-container>

                                <div>
                                    <span class="select-label text-info-800 mt-2">
                                        Unit<app-help-selector [selector]="1108">
                                        </app-help-selector>
                                    </span>
                                    <span class="alpaca-icon-required text-danger"></span>
                                </div>

                                <ng-container *ngTemplateOutlet="unitTemplate"></ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <div class="hub-step-action-buttons">
                        <button disabled class="hub-icon-button" matStepperPrevious>
                            <b><i class="icon-arrow-left13 mr-2"></i></b>
                            <span>Previous</span>
                        </button>

                        <button [disabled]="getFormGroupByTabIndex(0).invalid" class="hub-icon-button-mirrored"
                            matStepperNext>
                            <b><i class="icon-arrow-right14 ml-2"></i></b>
                            <span>Next</span>
                        </button>
                    </div>
                </cdk-step>

                <cdk-step label="Intermediate Result Details" [stepControl]="getFormGroupByTabIndex(1)">
                    <div class="step-content-container" [formGroup]="getFormGroupByTabIndex(1)">
                        <div class="step-content-header">
                            <p class="text-center font-weight-bold text-info-800 p-1">
                                Define your Intermediate Result Details
                            </p>
                        </div>

                        <div class="step-content-body hub-custom-scrollbar">
                            <ng-container *ngTemplateOutlet="scaleMeasureTemplate"></ng-container>

                            <div class="hub-select-container">

                                <div class="dashboard-visibility-panel">

                                    <div class="dashboard-visibility-input-row">
                                        <label>Leverage Indicator
                                            <app-help-selector [selector]="1205"></app-help-selector>
                                        </label>
                                    </div>

                                    <div class="dashboard-visibility-input-row">
                                        <mat-checkbox color="primary" formControlName="leverageIntermediateResult">Policy</mat-checkbox>
                                    </div>

                                    <div class="dashboard-visibility-input-row">
                                        <label>Key Result?
                                            <app-help-selector [selector]="1115"></app-help-selector>
                                        </label>
                                        
                                        <mat-checkbox color="primary" formControlName="keyResult"></mat-checkbox>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="hub-step-action-buttons">
                        <button class="hub-icon-button" cdkStepperPrevious>
                            <b><i class="icon-arrow-left13 mr-2"></i></b>
                            <span>Previous</span>
                        </button>
                        <button [disabled]="getFormGroupByTabIndex(1).invalid" class="hub-icon-button-mirrored"
                            cdkStepperNext>
                            <b><i class="icon-arrow-right14 ml-2"></i></b>
                            <span>Next</span>
                        </button>
                    </div>
                </cdk-step>

                <cdk-step label="Intermediate Result Statement" [stepControl]="getFormGroupByTabIndex(2)">
                    <div class="step-content-container" [formGroup]="getFormGroupByTabIndex(2)">
                        <div class="step-content-header">
                            <p class="text-center font-weight-bold sb-b p-1">
                                Define your Intermediate Result Statement
                            </p>
                        </div>
                        <div class="step-content-body">
                            <div>
                                <span class="select-label text-info-800">
                                    {{project?.projectType?.name}} Intermediate Result Statement
                                </span>
                                <span class="alpaca-icon-required text-danger"></span>
                            </div>

                            <textarea formControlName="intermediateResultStatement" name="intermediateStatement"
                                rows="3" cols="3" class="form-control record-description"
                                placeholder="Enter your intermediate result statement here..." maxlength="800"
                                style="margin-top: 0px; margin-bottom: 0px; height: 116px;" required></textarea>

                            <small class="hub-validation-error-message">
                                {{this.getValidationErrorMessages(2, "intermediateResultStatement")}}
                            </small>

                            <div class="mt-2" *ngIf="getFormGroupByTabIndex(0).controls.parentIr.value">
                                <span class="select-label text-info-800">For Reference:</span>
                                <div class="alert-secondary p-2">
                                    <div>
                                        <span class="select-label text-secondary">
                                            Parent Intermediate Result Statement<app-help-selector [selector]="1161">
                                            </app-help-selector>
                                        </span>
                                    </div>
                                    {{getFormGroupByTabIndex(0).controls.parentIr.value?.statement}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hub-step-action-buttons">
                        <button class="hub-icon-button" cdkStepperPrevious>
                            <b><i class="icon-arrow-left13 mr-2"></i></b>
                            <span>Previous</span>
                        </button>

                        <button class="hub-icon-button SaveButton" cdkStepperNext [ngClass]="{'saving': isSaving}"
                            [disabled]="intermediateResultForm.invalid || isSaving" (click)="save()">
                            <b>
                                <i class="icon-floppy-disk"></i>
                                <i class="icon-spinner4"></i>
                            </b>
                            {{existingIr ? 'Update Intermediate Result' : 'Create Intermediate Result'}}
                        </button>

                    </div>
                </cdk-step>

            </app-hub-stepper>

        </ng-container>

        <ng-container *ngIf="isStrategy">

            <div class="strategy-ir-panel">
                <div style="margin-top: 20px;">
                    <span class="select-label text-info-800">
                        Intermediate Result Metric<app-help-selector [selector]="1107">
                        </app-help-selector>
                    </span>
                    <span class="alpaca-icon-required text-danger"></span>
                </div>

                <ng-container *ngTemplateOutlet="metricNameTemplate"></ng-container>

                <span class="select-label text-info-800 d-block mt-2">
                    Unit<app-help-selector [selector]="1112">
                    </app-help-selector>
                </span>

                <ng-container *ngTemplateOutlet="unitTemplate"></ng-container>

                <ng-container *ngTemplateOutlet="scaleMeasureTemplate"></ng-container>

                <div style="margin-top: 15px">
                    <span class="select-label text-info-800">
                        Intermediate Result Statement<app-help-selector [selector]="1113">
                        </app-help-selector>
                    </span>

                    <textarea type="text" formControlName="intermediateResultStatement" name="intermediateStatement" rows="3"
                        cols="3" class="form-control record-description"
                        placeholder="Enter your intermediate result statement here..." maxlength="800"
                        style="margin-top: 0px; margin-bottom: 0px; height: 116px;" required></textarea>

                    <small class="hub-validation-error-message">
                        {{this.getValidationErrorMessages(2, "intermediateResultStatement")}}
                    </small>
                </div>

                <div class="dashboard-visibility-panel">
                    <div class="dashboard-visibility-input-row">
                        <span class="select-label d-block text-info-800">
                            Show on Dashboard?
                            <app-help-selector [selector]="1197"></app-help-selector>
                        </span>
                        <mat-checkbox color="primary" formControlName="showOnDashboard"></mat-checkbox>
                    </div>

                    <div class="dashboard-visibility-input-row" *ngIf="isKeyResultVisible">
                        <span class="select-label d-block text-info-800 ">
                            Key Result?
                            <app-help-selector [selector]="1115"></app-help-selector>
                        </span>
                        <mat-checkbox color="primary" formControlName="keyResult"></mat-checkbox>
                    </div>

                    <label>Leverage Indicator
                        <app-help-selector [selector]="1205"></app-help-selector>
                    </label>

                    <div class="dashboard-visibility-input-row">
                        <mat-checkbox color="primary" formControlName="leverageIntermediateResult">Policy</mat-checkbox>
                    </div>
                </div>

            </div>
        </ng-container>

    </mat-dialog-content>

    <div matDialogActions class="dialog-footer">

        <button (click)="cancel()" class="cancel-button" data-dismiss="modal">
            Cancel
        </button>

        <button *ngIf="isStrategy" type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
            [disabled]="intermediateResultForm.invalid || isSaving" (click)="save()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            Save
        </button>


    </div>

</div>
