import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, inject, Input, OnInit } from '@angular/core';
import { OutputDetailsModel, ProjectOutputModel } from '../../../../hub_schema/hubTypes';
import { OutputDetailService } from '../../../core/services/output-detail.service';
import { take } from 'rxjs';

enum tabs {
    outputs = 1,
    archive
}
@Component({
    selector: 'app-outputs-container',
    templateUrl: './outputs-container.component.html',
    animations: [
        trigger('expandCollapse', [
            state('open', style({ height: '100%', opacity: 1 })),
            state('closed', style({ height: 0, opacity: 0 })),
            transition('* => *', [animate('100ms')]),
        ]),
    ],
})
export class OutputsContainerComponent implements OnInit {

    private outputDetailService: OutputDetailService = inject(OutputDetailService);

    @Input() 
    public outputs: ProjectOutputModel[];

    public tabs = tabs;
    public activeTab: tabs = tabs.outputs;

    public activeOutputs: ProjectOutputModel[];
    public archivedOutputs: ProjectOutputModel[];

    public ngOnInit(): void {
        this.activeOutputs = this.outputs.filter(o => !o.isArchived);
        this.archivedOutputs = this.outputs.filter(o => o.isArchived);

        // pre-load all outputs' details (links show without a click required)
        for (let output of this.activeOutputs) {
            this.outputDetailService.getProjectOutputDetails(output.projectOutputId!).pipe(take(1)).subscribe((outputDetails: OutputDetailsModel) => {
                output.outputLinks = outputDetails.links;
                output.outputProgress = outputDetails.progress;
            });
        }
        for (let output of this.archivedOutputs) {
            this.outputDetailService.getProjectOutputDetails(output.projectOutputId!).pipe(take(1)).subscribe((outputDetails: OutputDetailsModel) => {
                output.outputLinks = outputDetails.links;
                output.outputProgress = outputDetails.progress;
            });
        }
    }

    public setTabTo(tab: tabs, e:Event): void {
        this.activeTab = tab;        
        e.preventDefault();
    }
}
