<div class="intermediate-result-detail-container" *ngIf="intermediateResult">

    <div class="ir-details-panel hub-custom-scrollbar">

        <div class="heading">Intermediate Result Statement</div>
        <div class="value">
            <p class="record-description">{{intermediateResult.statement}}</p>
        </div>

        <ng-container *ngIf="isStrategyIr">
            <div class="heading text-info-800">Show on Dashboard?</div>
            <div class="value">
                <p *ngIf="intermediateResult.isVisibleOnDashboard">Yes</p>
                <p *ngIf="!intermediateResult.isVisibleOnDashboard">No</p>
            </div>
        </ng-container>

        <div class="heading text-info-800">Leverage Indicator
            <app-help-selector [selector]="1205"></app-help-selector>
        </div>
        <div class="value">
            <p *ngIf="intermediateResult.hasLeveragedIntermediateResult">Yes</p>
            <p *ngIf="!intermediateResult.hasLeveragedIntermediateResult">No</p>
        </div>

        <div class="heading text-info-800">Key Result?
            <app-help-selector [selector]="1115"></app-help-selector>
        </div>
        <div class="value">
            <p *ngIf="intermediateResult.isKeyResult">Yes</p>
            <p *ngIf="!intermediateResult.isKeyResult">No</p>
        </div>

        <!-- METRIC VALUES -->
        <div class="active tab-pane fade show p-0" id="values">
            <div class="d-md-flex justify-content-between">
                <div class="heading">
                    {{isStrategyIr ? 'Intermediate Result Target Values' : 'Intermediate Result Start, Progress and Target Values'}}
                    ({{intermediateResult.irStatistics.targetCount + intermediateResult.irStatistics.progressCount}})
                </div>

                <div class="btn-group" ngbDropdown *ngIf="!intermediateResult.isArchived">
                    <div ngbDropdownToggle class="hub-dropdown-toggle">
                        <i class="icon-plus-circle2"></i>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button (click)="openAddEditTargetDialog(null, false)" href="#"
                            class="dropdown-item">
                            <i class="icon-plus-circle2"></i> Target
                        </button>

                        <button *ngIf="!isStrategyIr"
                            (click)="openAddEditProgressDialog(null)" href="#"
                            class="dropdown-item">
                            <i class="icon-plus-circle2"></i> Progress
                        </button>

                        <button *ngIf="!isStrategyIr" [disabled]="irHasStartMetric()"
                            (click)="openAddEditProgressDialog(null, true)" class="dropdown-item">
                            <i class="icon-plus-circle2"></i> Start
                        </button>

                    </div>
                </div>

            </div>

            <div class="table-responsive mb-2">
                <table class="table table-columned table-xs metric-table">
                    <thead>
                        <tr class="font-weight-bold">
                            <th class="text-right" width="20%">Date / Value Type</th>
                            <th class="text-right">Metric Value</th>
                            <th width="20%" *ngIf="!intermediateResult.isArchived">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Targets on Top -->
                        <tr *ngFor="let target of intermediateResult.targets" class="target-value">
                            <td class="text-right">
                                <span class="text-success date-label">Target</span>
                                <span class="d-block">{{target.targetDate | date:'yyyy-MM-dd'}}</span>
                            </td>

                            <td class="text-right">
                                <div class="value">
                                    {{target.targetValue | number : '0.0-20'}}
                                    <span class="unit">
                                        {{intermediateResult.unit}}
                                    </span>
                                </div>
                            </td>

                            <td *ngIf="!intermediateResult.isArchived">
                                <button type="button" (click)="openAddEditTargetDialog(target)"
                                    class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                    data-popup="tooltip" title="Edit value">
                                    <i class="icon-pencil"></i>
                                </button>
                                <button type="button" (click)="deleteIrTargetRequested(target)"
                                    class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                    title="Delete value">
                                    <i class="icon-trash"></i>
                                </button>
                            </td>
                        </tr>

                        <!-- Then Progress -->
                        <tr *ngFor="let progress of intermediateResult.progress"
                            [class]="progress.isStart ? 'start-value' : 'progress-value'">
                            <td class="text-right">
                                <span *ngIf="progress.isStart" class="text-primary date-label">Start</span>
                                <span *ngIf="!progress.isStart" class="text-dark date-label">Progress</span>
                                <span class="d-block">
                                    {{progress.progressDate | date:'yyyy-MM-dd'}}
                                </span>
                            </td>

                            <td class="text-right">
                                <div class="value">
                                    {{progress.progressValue | number}}
                                    <span class="unit">
                                        {{intermediateResult.unit}}
                                    </span>
                                </div>
                                <div class="attribution record-description">
                                    {{progress.attributionLevel?.name}}
                                </div>
                                <div class="attribution-text record-description">
                                    {{progress.attributionDescription}}
                                </div>
                            </td>

                            <td *ngIf="!intermediateResult.isArchived">
                                <button type="button"
                                    (click)="openAddEditProgressDialog(progress, progress.isStart)"
                                    class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                    data-popup="tooltip" title="Edit value">
                                    <i class="icon-pencil"></i>
                                </button>
                                <button type="button"
                                    (click)="deleteIrProgressRequested(progress)"
                                    class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                    title="Delete value">
                                    <i class="icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <ng-container *ngIf="areDetailsLoaded()">
                <div *ngIf="!doDetailsExist()"
                    class="alert alert-info alert-styled-left shadow-0">
                    There are no intermediate result values defined yet.
                </div>
            </ng-container>

        </div>
    </div>
</div>
